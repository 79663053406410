import { MouseEvent } from 'react';

import { Button, Card, Checkbox, Spin, Tooltip } from 'antd';
import cn from 'classnames';
import { validators } from 'helpers/utils';
import { upperFirst } from 'lodash';

import { formatFileName, MediaItem } from '@cpm/scanifly-shared-data';
import { isGeneralBucket } from '@cpm/scanifly-shared-data/lib/util/mediaUtils';
import { ReactComponent as VideoIcon } from 'assets/camera.svg';
import { ReactComponent as FileIcon } from 'assets/icons/file-image-solid.svg';
import { ReactComponent as ImageIcon } from 'assets/icons/image-solid.svg';
import { ReactComponent as MapIcon } from 'assets/icons/map-solid.svg';
import { ReactComponent as ProjectThumbnailIcon } from 'assets/icons/project-thumbnail-icon.svg';
import placeholderImageUrl from 'assets/thumbnail-placeholder.svg';
import { UPLOADING } from '../constants';
import './Thumbnail.scss';
import { ThumbnailProps } from './types';

const Thumbnail = ({
  mediaItem,
  handleRenderMap,
  handleSelectImage,
  handleCheck,
  index,
  checked,
  projectThumbnail,
  isActiveImage,
  popup,
  preview,
  isListView,
  isLoading = false,
}: ThumbnailProps) => {
  const isThumbnailLoading = !preview && !(mediaItem as MediaItem).projectId && !isListView;
  const type = validators.isImage(mediaItem) ? 'image' : 'file';

  const getMediaGeolocation = () => {
    return (mediaItem as MediaItem).geolocation || mediaItem?.meta?.geolocation;
  };

  const thumbnailOnClick = (evt: MouseEvent<HTMLElement> | undefined) => {
    handleSelectImage({
      mediaItem: mediaItem as MediaItem,
      evt,
      index,
    });
  };

  const fileDisplayName =
    (mediaItem as MediaItem)?.displayValue ?? formatFileName({ media: mediaItem as MediaItem });

  return (
    <div key={mediaItem.id} className="Thumbnail-Wrapper" data-testid={`thumbnail-image-${index}`}>
      <Card
        className="Thumbnail"
        cover={
          !isListView &&
          (validators.isVideo(mediaItem) ? (
            <VideoIcon />
          ) : (
            <>
              {isThumbnailLoading && <Spin className="Thumbnail-Spinner" size="small" />}
              <img
                crossOrigin={
                  isGeneralBucket((mediaItem as MediaItem).thumbnailUrl ?? '')
                    ? undefined
                    : 'anonymous'
                }
                src={preview || (mediaItem as MediaItem).thumbnailUrl || placeholderImageUrl}
                className={cn({ 'Hidden-Visibility': isThumbnailLoading })}
                aria-hidden={isThumbnailLoading}
                alt="drone thumbnail"
              />
            </>
          ))
        }
      >
        <div className="Thumbnail-Name">
          {isLoading && <Spin size="small" className="Thumbnail-Loader" />}
          {!isLoading &&
            isListView &&
            (projectThumbnail ? (
              <Tooltip
                placement="top"
                title="This image is used as your project's thumbnail"
                arrowPointAtCenter
              >
                <ProjectThumbnailIcon />
              </Tooltip>
            ) : (
              <FileIcon />
            ))}
          <span>{isLoading ? UPLOADING : fileDisplayName}</span>
        </div>
        <div className="Thumbnail-Buttons">
          <Tooltip placement="top" title={`View ${type} preview`} arrowPointAtCenter>
            <Button
              className={cn('Button--Filter', {
                'Button--Active': isActiveImage,
              })}
              onClick={thumbnailOnClick}
              aria-disabled={false}
              aria-label="display preview of upload"
            >
              <ImageIcon />
            </Button>
          </Tooltip>
          <Tooltip
            placement="top"
            title={
              getMediaGeolocation()
                ? `View ${type} on map`
                : `${upperFirst(type)} does not have a location`
            }
            arrowPointAtCenter
          >
            <Button
              className={cn('Button--Filter', 'Thumbnail-Map-Button', {
                'Button--Active': popup,
                'Button--Filter--Disabled': !getMediaGeolocation(),
              })}
              onClick={(event) => handleRenderMap(mediaItem as MediaItem, event)}
              aria-disabled={!getMediaGeolocation()}
              aria-label="display upload over map"
            >
              <MapIcon />
            </Button>
          </Tooltip>
        </div>
      </Card>
      <div className="Thumbnail-Checkbox" data-testid="select-upload">
        <Checkbox
          onChange={(event) => handleCheck(event, mediaItem as MediaItem)}
          checked={checked}
          aria-checked={checked}
          disabled={isLoading}
          aria-disabled={isLoading}
          aria-label="select upload"
        />
      </div>
    </div>
  );
};

export default Thumbnail;
