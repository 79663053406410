import { MediaItem, SurveyMedia } from '@cpm/scanifly-shared-data';
import DeleteConfirmationModal from 'components/Modal/DeleteConfirmationModal';
import useToggle from 'helpers/hooks/useToggle';
import React, { Dispatch, SetStateAction, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { projectMediasDeleted } from 'state/slices/mediasSlice';
import { AppDispatch } from 'state/store';
import styled from 'styled-components';
import { Thumbnail } from '../Thumbnail/Thumbnail';

const ThumbnailGridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: start;
  gap: 0.5rem;
  margin: 1rem 0;
  padding-bottom: 10rem;
`;

export const ThumbnailGrid = ({
  album,
  selectedImages,
  setSelectedImages,
  handleDownload,
  categoryName,
}: {
  album: (SurveyMedia | MediaItem)[];
  selectedImages: Record<string, MediaItem | SurveyMedia>;
  setSelectedImages: Dispatch<SetStateAction<Record<string, MediaItem | SurveyMedia>>>;
  handleDownload?: () => void;
  categoryName: string;
}) => {
  const [openMiniMenuId, setOpenMiniMenuId] = useState<null | string>(null);
  const [isDeleteModalVisible, toggleDeleteModalVisibility] = useToggle(false);
  const { t } = useTranslation();
  const dispatch: AppDispatch = useDispatch();

  const selectedImageLength = Object.keys(selectedImages).length;

  const handleClick = (
    img: SurveyMedia | MediaItem,
    e?: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    const selectedImageInCache = selectedImages[img.id];
    if (e?.getModifierState('Shift')) {
      if (selectedImageLength >= 1) {
        setSelectedImages((prevState) => {
          const startIndex = album.indexOf(Object.values(prevState)[0]);
          const endIndex = album.indexOf(img);
          const imagesToBeSelected: { [id: string]: SurveyMedia | MediaItem } = {};
          album
            .slice(Math.min(startIndex, endIndex), Math.max(startIndex, endIndex) + 1)
            .forEach((image) => (imagesToBeSelected[image.id] = image));
          return imagesToBeSelected;
        });
      } else {
        setSelectedImages((prevState) => ({ ...prevState, [img.id]: img }));
      }
    } else if (e?.getModifierState('Control') || e?.getModifierState('Meta')) {
      if (selectedImageInCache) {
        setSelectedImages((prevState) => {
          const newState = { ...prevState };
          delete newState[img.id];
          return newState;
        });
      } else {
        setSelectedImages((prevState) => ({ ...prevState, [img.id]: img }));
      }
    } else {
      if (selectedImageInCache && Object.keys(selectedImages).length === 1) {
        setSelectedImages({});
      } else {
        setSelectedImages({ [img.id]: img });
      }
    }
    e?.stopPropagation();
  };

  const handleDelete = () => {
    dispatch(
      projectMediasDeleted({
        medias: { medias: Object.values(selectedImages).map((media) => media.id) },
        mediaCategoryName: categoryName,
      })
    );
    setSelectedImages({});
  };

  const toggleDeleteModal = () => {
    setOpenMiniMenuId(null);
    toggleDeleteModalVisibility();
  };

  return (
    <ThumbnailGridWrapper>
      <DeleteConfirmationModal
        title={t('AlbumView.deleteModalTitle')}
        description={
          selectedImageLength === 1
            ? t('AlbumView.deleteModalTextForSingleFile')
            : t('AlbumView.deleteModalText', {
                selectedImageLength,
              })
        }
        actionButtonLabel={t('Generic.yes')}
        isVisible={isDeleteModalVisible}
        setIsVisible={toggleDeleteModal}
        actionButtonOnClick={handleDelete}
      />
      {album.map((image) => (
        <Thumbnail
          key={image.id}
          image={image}
          selectedImages={selectedImages}
          handleClick={(image, e) => handleClick(image, e)}
          setOpenMiniMenuId={setOpenMiniMenuId}
          openMiniMenuId={openMiniMenuId}
          handleDownload={handleDownload}
          toggleDeleteModal={toggleDeleteModal}
        />
      ))}
    </ThumbnailGridWrapper>
  );
};
