import {
  DESIGN_SERVICE_CREDITS,
  DESIGN_TIME_ESTIMATES,
  estimateTurnaroundTimeForSmall,
  FEATURE_LIST,
  GENERAL_SERVICE_CREDITS,
  TIER_LEVEL,
  TIER_LEVEL_DESCRIPTIONS,
} from '@cpm/scanifly-shared-data';

import {
  ADMIN_PROJECT_STATUSES,
  NON_ADMIN_PROJECT_STATUSES,
} from 'helpers/constants/projectStatuses';
import { i18n } from 'helpers/utils/translations';

export const DESIGN_ACTIONS = {
  completed: i18n.t('DesignActions.completed'),
  dataRequest: i18n.t('DesignActions.dataRequest'),
  designing: i18n.t('DesignActions.designing'),
  dataRefinement: i18n.t('DesignActions.dataRefinement'),
  requestApproval: i18n.t('DesignActions.requestApproval'),
} as const;

export const SIZED_FOR_CATEGORIES = {
  none: 'none',
  consumption: 'consumption',
  production: 'production',
  systemSize: 'systemSize',
} as const;

const designServiceProviderName = {
  title: i18n.t('TableHeadings.designServiceProvider'),
  dataIndex: 'designServiceProviderName',
};

const turnaroundTime = {
  title: i18n.t('TableHeadings.turnaroundTime'),
  dataIndex: 'completedDate',
};

const inDesignTime = {
  title: i18n.t('TableHeadings.designTime'),
  dataIndex: 'designStartDate',
};

const modelRevisionCount = {
  title: i18n.t('TableHeadings.revisionCount'),
  dataIndex: 'modelRevisionCount',
};

export const getDSPColumnTitles = (isScaniflyAdmin: boolean) => {
  if (isScaniflyAdmin) {
    DESIGN_SERVICES_QUEUE_CSV_COLUMN_TITLES.splice(1, 0, designServiceProviderName);
    DESIGN_SERVICES_QUEUE_CSV_COLUMN_TITLES.push(turnaroundTime, inDesignTime, modelRevisionCount);
  }
  return DESIGN_SERVICES_QUEUE_CSV_COLUMN_TITLES;
};

export const DESIGN_SERVICES_QUEUE_CSV_COLUMN_TITLES = [
  {
    title: i18n.t('TableHeadings.project'),
    dataIndex: 'projectName',
  },
  {
    title: i18n.t('TableHeadings.designStatus'),
    dataIndex: 'status',
  },
  {
    title: i18n.t('TableHeadings.tierLevel'),
    dataIndex: 'tierLevel',
  },
  {
    title: i18n.t('CSVExportTitles.expedited'),
    dataIndex: 'isExpedited',
  },
  {
    title: i18n.t('CSVExportTitles.newBuildPlans'),
    dataIndex: 'newBuildPlansRequired',
  },
  {
    title: i18n.t('TableHeadings.company'),
    dataIndex: 'companyName',
  },
  {
    title: i18n.t('TableHeadings.systemSize'),
    dataIndex: 'systemSize',
  },
  {
    title: i18n.t('TableHeadings.type'),
    dataIndex: 'projectType',
  },
  {
    title: i18n.t('TableHeadings.orderId'),
    dataIndex: 'id',
  },
  {
    title: i18n.t('TableHeadings.requestedAt'),
    dataIndex: 'createdAt',
  },
];

const revisionColumns = [
  {
    title: i18n.t('TableHeadings.revisionCount'),
    dataIndex: 'modelRevisionCount',
  },
  {
    title: i18n.t('TableHeadings.revisionReasons'),
    dataIndex: 'modelRevision',
  },
];

export const DESIGN_SERVICES_QUEUE_COMPANY_CSV_COLUMN_TITLES = [
  ...DESIGN_SERVICES_QUEUE_CSV_COLUMN_TITLES.filter(
    (item) =>
      item.title !== i18n.t('TableHeadings.company') &&
      item.title !== i18n.t('TableHeadings.systemSize')
  ),
  ...revisionColumns,
  turnaroundTime,
];

export type DesignOption = {
  tierLevel: TIER_LEVEL;
  serviceType: string;
  credits: number;
  timeEstimate: string | number | undefined;
  specifications: string[];
  hasNewTag?: boolean;
  isExpedited?: boolean;
  disclaimerText?: string;
  timeEstimateForCommercial?: string | number | undefined;
  creditsForCommercial?: number;
};

export const DESIGN_OPTIONS_FOR_LARGE_PROJECTS: DesignOption[] = [
  {
    tierLevel: TIER_LEVEL.wireframeForLarge,
    serviceType: TIER_LEVEL_DESCRIPTIONS.wireframe,
    credits: DESIGN_SERVICE_CREDITS[TIER_LEVEL.wireframeForLarge],
    timeEstimate: Number(DESIGN_TIME_ESTIMATES.wireframeForLarge),
    specifications: [
      i18n.t('DesignServiceSpecifications.roofOutlines'),
      i18n.t('DesignServiceSpecifications.obstructions'),
    ],
  },
  {
    tierLevel: TIER_LEVEL.planSetForLarge,
    serviceType: TIER_LEVEL_DESCRIPTIONS.planSet,
    credits: DESIGN_SERVICE_CREDITS[TIER_LEVEL.planSetForLarge],
    timeEstimate: DESIGN_TIME_ESTIMATES.planSetForLarge,
    specifications: [
      i18n.t('DesignServiceSpecifications.sitePlan'),
      i18n.t('DesignServiceSpecifications.fullPlanSet'),
    ],
  },
];

export const LARGE_UPGRADE_OPTIONS: DesignOption[] = [
  {
    tierLevel: TIER_LEVEL.planSetForLarge,
    serviceType: TIER_LEVEL_DESCRIPTIONS.planSet,
    credits:
      DESIGN_SERVICE_CREDITS[TIER_LEVEL.planSetForLarge] -
      DESIGN_SERVICE_CREDITS[TIER_LEVEL.wireframeForLarge],
    timeEstimate: DESIGN_TIME_ESTIMATES.planSetForLarge,
    specifications: [
      i18n.t('DesignServiceSpecifications.sitePlan'),
      i18n.t('DesignServiceSpecifications.fullPlanSet'),
    ],
  },
];

const SITE_MODELING = {
  tierLevel: TIER_LEVEL.siteModeling,
  serviceType: TIER_LEVEL_DESCRIPTIONS.siteModeling,
  credits: DESIGN_SERVICE_CREDITS.siteModeling,
  timeEstimate: estimateTurnaroundTimeForSmall({ tierLevel: TIER_LEVEL.wireframe }).design,
  specifications: [
    i18n.t('DesignServiceSpecifications.roofModeling'),
    i18n.t('DesignServiceSpecifications.obstructions'),
    i18n.t('DesignServiceSpecifications.vegetation'),
  ],
  timeEstimateForCommercial: estimateTurnaroundTimeForSmall({
    tierLevel: TIER_LEVEL.siteModeling,
    isExpedited: false,
    isCommercial: true,
  }).design,
  creditsForCommercial:
    DESIGN_SERVICE_CREDITS.siteModeling +
    GENERAL_SERVICE_CREDITS[FEATURE_LIST.DESIGN_SERVICE_COMMERCIAL_SITE_MODELING],
};

const MAX_FILL = {
  tierLevel: TIER_LEVEL.maxFill,
  serviceType: TIER_LEVEL_DESCRIPTIONS.maxFill,
  credits: DESIGN_SERVICE_CREDITS.maxFill,
  timeEstimate: estimateTurnaroundTimeForSmall({ tierLevel: TIER_LEVEL.wireframe }).design,
  specifications: [
    i18n.t('DesignServiceSpecifications.siteModeling'),
    i18n.t('DesignServiceSpecifications.modules'),
    i18n.t('DesignServiceSpecifications.shadingAnalysis'),
  ],
  timeEstimateForCommercial: estimateTurnaroundTimeForSmall({
    tierLevel: TIER_LEVEL.maxFill,
    isExpedited: false,
    isCommercial: true,
  }).design,
  creditsForCommercial:
    DESIGN_SERVICE_CREDITS.maxFill +
    GENERAL_SERVICE_CREDITS[FEATURE_LIST.DESIGN_SERVICE_COMMERCIAL_MAX_FILL],
};

const PRELIM_PLANSET = {
  tierLevel: TIER_LEVEL.planSetForPrelim,
  serviceType: TIER_LEVEL_DESCRIPTIONS.planSet,
  credits: DESIGN_SERVICE_CREDITS.planSetForPrelim,
  timeEstimate: estimateTurnaroundTimeForSmall({ tierLevel: TIER_LEVEL.planSetForPrelim }).design,
  specifications: [
    i18n.t('DesignServiceSpecifications.propertyLines'),
    i18n.t('DesignServiceSpecifications.dwgPlanSet'),
    i18n.t('DesignServiceSpecifications.fullPlanSet'),
  ],
  hasNewTag: true,
  disclaimerText: i18n.t('DesignServiceSpecifications.prelimPlanSetDisclaimer'),
  timeEstimateForCommercial: estimateTurnaroundTimeForSmall({
    tierLevel: TIER_LEVEL.planSetForPrelim,
    isExpedited: false,
    isCommercial: true,
  }).design,
  creditsForCommercial:
    DESIGN_SERVICE_CREDITS.planSetForPrelim +
    GENERAL_SERVICE_CREDITS[FEATURE_LIST.DESIGN_SERVICE_PRELIM_PLANSET],
};

export const PRELIM_DESIGN_OPTIONS: DesignOption[] = [
  SITE_MODELING,
  {
    ...SITE_MODELING,
    isExpedited: true,
    timeEstimate: estimateTurnaroundTimeForSmall({
      tierLevel: TIER_LEVEL.siteModeling,
      isExpedited: true,
    }).design,
    credits:
      DESIGN_SERVICE_CREDITS.siteModeling +
      GENERAL_SERVICE_CREDITS[FEATURE_LIST.DESIGN_SERVICE_EXPEDITED_PRELIM],
    timeEstimateForCommercial: estimateTurnaroundTimeForSmall({
      tierLevel: TIER_LEVEL.siteModeling,
      isExpedited: true,
      isCommercial: true,
    }).design,
    creditsForCommercial:
      DESIGN_SERVICE_CREDITS.siteModeling +
      GENERAL_SERVICE_CREDITS[FEATURE_LIST.DESIGN_SERVICE_COMMERCIAL_EXPEDITED_SITE_MODELING] +
      GENERAL_SERVICE_CREDITS[FEATURE_LIST.DESIGN_SERVICE_EXPEDITED_PRELIM],
  },
  MAX_FILL,
  {
    ...MAX_FILL,
    isExpedited: true,
    timeEstimate: estimateTurnaroundTimeForSmall({
      tierLevel: TIER_LEVEL.maxFill,
      isExpedited: true,
    }).design,
    credits:
      DESIGN_SERVICE_CREDITS.maxFill +
      GENERAL_SERVICE_CREDITS[FEATURE_LIST.DESIGN_SERVICE_EXPEDITED_PRELIM],
    timeEstimateForCommercial: estimateTurnaroundTimeForSmall({
      tierLevel: TIER_LEVEL.maxFill,
      isExpedited: true,
      isCommercial: true,
    }).design,
    creditsForCommercial:
      DESIGN_SERVICE_CREDITS.maxFill +
      GENERAL_SERVICE_CREDITS[FEATURE_LIST.DESIGN_SERVICE_COMMERCIAL_EXPEDITED_MAX_FILL] +
      GENERAL_SERVICE_CREDITS[FEATURE_LIST.DESIGN_SERVICE_EXPEDITED_PRELIM],
  },
  PRELIM_PLANSET,
];

export const WIREFRAME = {
  tierLevel: TIER_LEVEL.wireframe,
  serviceType: TIER_LEVEL_DESCRIPTIONS.wireframe,
  credits: DESIGN_SERVICE_CREDITS.wireframe,
  timeEstimate: estimateTurnaroundTimeForSmall({ tierLevel: TIER_LEVEL.wireframe }).design,
  specifications: [
    i18n.t('DesignServiceSpecifications.roofOutlines'),
    i18n.t('DesignServiceSpecifications.obstructions'),
  ],
};

export const TRUE_UP = {
  tierLevel: TIER_LEVEL.trueUp,
  serviceType: TIER_LEVEL_DESCRIPTIONS.trueUp,
  credits: DESIGN_SERVICE_CREDITS.trueUp,
  timeEstimate: estimateTurnaroundTimeForSmall({ tierLevel: TIER_LEVEL.trueUp }).design,
  hasNewTag: true,
  specifications: [
    i18n.t('DesignServiceSpecifications.wireframe'),
    i18n.t('DesignServiceSpecifications.modules'),
    i18n.t('DesignServiceSpecifications.shadingAnalysis'),
  ],
};

export const SITE_PLAN_WITHOUT_TRUE_UP = {
  tierLevel: TIER_LEVEL.sitePlan,
  serviceType: TIER_LEVEL_DESCRIPTIONS.sitePlan,
  credits: DESIGN_SERVICE_CREDITS.sitePlan,
  timeEstimate: estimateTurnaroundTimeForSmall({ tierLevel: TIER_LEVEL.sitePlan }).design,
  specifications: [
    `${i18n.t('DesignServiceSpecifications.siteModeling')} / ${i18n.t('DesignServiceSpecifications.wireframes')}`,
    i18n.t('DesignServiceSpecifications.modules'),
    i18n.t('DesignServiceSpecifications.shadingAnalysis'),
    i18n.t('DesignServiceSpecifications.dwg'),
  ],
};

export const SITE_PLAN = {
  tierLevel: TIER_LEVEL.sitePlan,
  serviceType: TIER_LEVEL_DESCRIPTIONS.sitePlan,
  credits: DESIGN_SERVICE_CREDITS.sitePlan,
  timeEstimate: estimateTurnaroundTimeForSmall({ tierLevel: TIER_LEVEL.sitePlan }).design,
  specifications: [
    i18n.t('DesignServiceSpecifications.trueUp'),
    i18n.t('DesignServiceSpecifications.propertyLines'),
    i18n.t('DesignServiceSpecifications.dwg'),
    i18n.t('DesignServiceSpecifications.pdf'),
  ],
};

export const PLAN_SET = {
  tierLevel: TIER_LEVEL.planSet,
  serviceType: TIER_LEVEL_DESCRIPTIONS.planSet,
  credits: DESIGN_SERVICE_CREDITS.planSet,
  timeEstimate: estimateTurnaroundTimeForSmall({ tierLevel: TIER_LEVEL.planSet }).design,
  specifications: [
    i18n.t('DesignServiceSpecifications.sitePlan'),
    i18n.t('DesignServiceSpecifications.fullPlanSet'),
  ],
};

export const DESIGN_OPTIONS: DesignOption[] = [WIREFRAME, TRUE_UP, SITE_PLAN, PLAN_SET];

export const WIREFRAME_UPGRADE_DESIGN_OPTIONS: DesignOption[] = [
  { ...TRUE_UP, credits: DESIGN_SERVICE_CREDITS.trueUp - DESIGN_SERVICE_CREDITS.wireframe },
  { ...SITE_PLAN, credits: DESIGN_SERVICE_CREDITS.sitePlan - DESIGN_SERVICE_CREDITS.wireframe },
  { ...PLAN_SET, credits: DESIGN_SERVICE_CREDITS.planSet - DESIGN_SERVICE_CREDITS.wireframe },
];

export const SITEPLAN_UPGRADE_DESIGN_OPTIONS: DesignOption[] = [
  { ...PLAN_SET, credits: DESIGN_SERVICE_CREDITS.planSet - DESIGN_SERVICE_CREDITS.sitePlan },
];

export const TRUE_UP_UPGRADE_DESIGN_OPTIONS: DesignOption[] = [
  { ...SITE_PLAN, credits: DESIGN_SERVICE_CREDITS.sitePlan - DESIGN_SERVICE_CREDITS.trueUp },
  { ...PLAN_SET, credits: DESIGN_SERVICE_CREDITS.planSet - DESIGN_SERVICE_CREDITS.trueUp },
];

export const processingStatuses = [
  ADMIN_PROJECT_STATUSES.PROCESSING,
  ADMIN_PROJECT_STATUSES.DRONE_IMAGES_UPLOADED,
  NON_ADMIN_PROJECT_STATUSES.UPLOAD_PROCESSING,
  NON_ADMIN_PROJECT_STATUSES.DRONE_IMAGES_UPLOADING,
  NON_ADMIN_PROJECT_STATUSES.DRONE_IMAGES_UPLOADED,
];

export const errorStatuses = [
  ADMIN_PROJECT_STATUSES.ERROR,
  NON_ADMIN_PROJECT_STATUSES.UPLOAD_ERROR,
];

export const completedStatuses = [
  ADMIN_PROJECT_STATUSES.PROCESSED,
  NON_ADMIN_PROJECT_STATUSES.UPLOAD_COMPLETE,
];

export const COLORS = {
  success: '#27e2a4',
  error: '#ff4d4d',
};

export const CANCELLATION_OPTIONS = [
  {
    name: 'noNeed',
    field: 'NO_NEED',
    text: i18n.t('DesignService.cancelOrderModal.options.noNeed'),
  },
  {
    name: 'customerMovingForward',
    field: 'CUSTOMER_MOVING_FORWARD',
    text: i18n.t('DesignService.cancelOrderModal.options.customerNotMovingForward'),
  },
  {
    name: 'tooLong',
    field: 'TOO_LONG',
    text: i18n.t('DesignService.cancelOrderModal.options.tooLong'),
  },
  { name: 'other', field: 'OTHER', text: i18n.t('DesignService.cancelOrderModal.options.other') },
];

export const MODEL_PROBLEMS = [
  {
    name: 'incorrectObstructionModeling',
    field: 'INCORRECT_OBSTRUCTION_MODELING',
    text: i18n.t('DesignService.revisionModal.options.incorrectObstructions'),
  },
  {
    name: 'incorrectRoofModeling',
    field: 'INCORRECT_ROOF_MODELING',
    text: i18n.t('DesignService.revisionModal.options.incorrectRoofSurfaces'),
  },
  {
    name: 'incorrectShadeAnalysis',
    field: 'INCORRECT_SHADE_ANALYSIS',
    text: i18n.t('DesignService.revisionModal.options.incorrectShadeAnalysis'),
  },
  {
    name: 'missingTrees',
    field: 'MISSING_TREES',
    text: i18n.t('DesignService.revisionModal.options.missingTrees'),
  },
  {
    name: 'ahjRejection',
    field: 'AHJ_REJECTION',
    text: i18n.t('DesignService.revisionModal.options.ahjRejection'),
  },
  { name: 'other', field: 'OTHER', text: i18n.t('DesignService.revisionModal.options.other') },
];

export const MODALS = {
  CANCELATION: 'cancelation',
  REVISION: 'revision',
};

export const FORM_CONTROLS_MODEL = {
  INCORRECT_OBSTRUCTION_MODELING: 'incorrectObstructionModeling',
  INCORRECT_ROOF_MODELING: 'incorrectRoofModeling',
  INCORRECT_SHADE_ANALYSIS: 'incorrectShadeAnalysis',
  MISSING_TREES: 'missingTrees',
  AHJ_REJECTION: 'ahjRejection',
  OTHER: 'other',
  NOTE: 'note',
  EXTRA_FEES_APPROVAL: 'extraFeesApproval',
};

export const MAX_LENGTH_FOR_REVISION_NOTE = 2000;
export const MAX_LENGTH_FOR_CANCELATION_NOTE = 300;
export const MAX_LENGTH_FOR_ADDITIONAL_NOTES = 10000;

export const FORM_CONTROLS = {
  NO_NEED: 'noNeed',
  CUSTOMER_MOVING_FORWARD: 'customerMovingForward',
  TOO_LONG: 'tooLong',
  OTHER: 'other',
  CANCEL_NOTE: 'cancelNote',
};

export const initialValues = {
  [FORM_CONTROLS.NO_NEED]: false,
  [FORM_CONTROLS.CUSTOMER_MOVING_FORWARD]: false,
  [FORM_CONTROLS.TOO_LONG]: false,
  [FORM_CONTROLS.OTHER]: false,
  [FORM_CONTROLS.CANCEL_NOTE]: '',
};

export const SERVICE_REQUEST_OPTIONS = [
  i18n.t('DesignService.residential'),
  i18n.t('DesignService.commercial'),
];

export const REMOTE_DESIGN_STATUSES = [
  TIER_LEVEL.siteModeling,
  TIER_LEVEL.maxFill,
  TIER_LEVEL.planSetForPrelim,
];
