import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import { Avatar, Badge, Card } from 'antd';
import confirm from 'antd/lib/modal/confirm';
import cn from 'classnames';

import { notesActions } from 'state/slices/notes';
import { AppDispatch, RootState } from 'state/store';

import { DropdownMenu, MenuMini } from 'components';

import colors from 'helpers/constants/colors';
import { CONFIRM_PROPS } from 'helpers/constants/modals';
import useToggle from 'helpers/hooks/useToggle';
import { validators } from 'helpers/utils';
import { formatDate, formatTimeStamp } from 'helpers/utils/dateFormat';

import { ReactComponent as VideoIcon } from 'assets/camera.svg';
import { ReactComponent as CancelIcon } from 'assets/icons/cancel-icon.svg';
import { ReactComponent as PencilIcon } from 'assets/icons/pencil-icon.svg';
import placeholderImage from 'assets/thumbnail-placeholder.svg';
import UserAvatarIcon from 'assets/user-avatar.svg';

import CommentParagraph from '../CommentParagraph/CommentParagraph';
import './Note.scss';
import { NoteProps } from './types';

const Note = ({ note, selected, setSelectedNote, setSelectedImage }: NoteProps) => {
  const [isMenuOpen, toggleMenu] = useToggle();
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const currentAccessToken = localStorage.getItem('accessToken');

  const { currentUser } = useSelector((state: RootState) => state.users);

  const handleEdit = () => {
    setSelectedNote(note);
  };

  const handleDelete = () => {
    confirm({
      title: t('Notes.deleteComment'),
      content: t('Notes.confirmDeleteComment'),
      okText: t('Notes.deleteComment'),
      okButtonProps: { style: { background: colors.red } },
      onOk: () => {
        if (note.project && note.id) {
          dispatch(
            notesActions.deleteNote({
              projectId: note.project as string,
              noteId: note.id,
            })
          );
        }
        if (selected) {
          setSelectedImage(null);
        }
      },
      ...CONFIRM_PROPS,
    });
  };

  const Dropdown = () => {
    return (
      <>
        <li>
          <button onClick={handleEdit} className="DropdownMenu-Item">
            <PencilIcon />
            {t('Notes.editComment')}
          </button>
        </li>
        <li>
          <button onClick={handleDelete} className="DropdownMenu-Item">
            <CancelIcon />
            {t('Notes.deleteComment')}
          </button>
        </li>
      </>
    );
  };

  return (
    <>
      <Card className={cn('Note', { 'Note--Selected': selected })}>
        <div
          className={cn('Note-Wrapper', {
            'Note-Wrapper--Menu': note.createdBy?.id === currentUser?.id,
          })}
        >
          <div
            role={note.media?.length ? 'button' : undefined}
            tabIndex={note.media?.length && 0}
            aria-hidden={!note.media?.length}
            onClick={() => setSelectedImage(note.media[0])}
            onKeyDown={(e) => e.key === 'Enter' && setSelectedImage(note.media[0])}
          >
            {!!note.media?.length &&
              (validators.isVideo(note.media[0]) ? (
                <VideoIcon className="Note-Thumbnail Note-Thumbnail--Video" />
              ) : (
                <img
                  src={note.media[0].thumbnailUrl || placeholderImage}
                  alt="thumbnail"
                  className="Note-Thumbnail"
                />
              ))}
            {note.media?.length > 1 && (
              <Badge className="Note-Thumbnail-Count" count={note.media.length} />
            )}
          </div>
          <CommentParagraph comment={note.text} />
        </div>
        {note.createdBy?.id === currentUser?.id && (
          <div className="Note-Menu">
            <MenuMini onClick={toggleMenu} />
            {isMenuOpen && (
              <DropdownMenu toggleMenu={toggleMenu}>
                <Dropdown />
              </DropdownMenu>
            )}
          </div>
        )}
      </Card>
      <div className="Note-Footer">
        <div className="Note-User">
          <Avatar
            alt="Avatar"
            src={
              note.createdBy?.imageUrl
                ? `${process.env.REACT_APP_API}/users/${note.createdBy.id}/image?token=${currentAccessToken}`
                : UserAvatarIcon
            }
            className="Note-Avatar"
          />
          <div>
            <div className="Note-Name">
              {note.createdBy?.firstName} {note.createdBy?.lastName}
            </div>
            <div className="Note-Role">{note.createdBy?.positionDescription}</div>
          </div>
        </div>
        <div className="Note-Updated">
          <p>{formatTimeStamp(note.createdAt)}</p>
          <p>{formatDate(note.createdAt)}</p>
        </div>
      </div>
    </>
  );
};

export default Note;
