import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  DESIGN_SERVICE_CREDITS,
  DESIGN_STATUS,
  DESIGN_TIER_LEVEL_FILTERS,
  TIER_LEVEL,
} from '@cpm/scanifly-shared-data';
import { Button } from 'antd';
import ServiceRequest from 'types/ServiceRequest';

import { RootState } from 'state/store';

import { orderDesignRoute } from 'helpers/constants/routes';
import { formatDate } from 'helpers/utils/dateFormat';
import { calculateTierLevelCost } from 'screens/DesignServices/helpers/calculateTierLevelCost';
import { handleMyProjectsButtonClick } from 'screens/DesignServices/helpers/handleMyProjectsButtonClick';

import { ReactComponent as DroneIcon } from 'assets/icons/drone-icon.svg';

const DesignCanceled = ({ canceledDesign }: { canceledDesign: ServiceRequest }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { serviceRequests } = useSelector((state: RootState) => state.designServiceRequests);
  const { tierLevel, project: projectId, upgradedFrom } = canceledDesign || {};
  const upgrade = Boolean(upgradedFrom);

  const hasSitePlanRequest = serviceRequests
    ? serviceRequests.filter(
        (r) => r.tierLevel === TIER_LEVEL.sitePlan && r.status !== DESIGN_STATUS.canceled
      ).length
    : 0;

  const hasTrueUpRequest = serviceRequests
    ? serviceRequests.filter(
        (r) => r.tierLevel === TIER_LEVEL.trueUp && r.status !== DESIGN_STATUS.canceled
      ).length
    : 0;

  const tierLevelCost = calculateTierLevelCost({
    upgrade,
    hasSitePlanRequest,
    hasTrueUpRequest,
    tierLevel,
  });

  return (
    <div className="DesignStatus-CancelWrapper">
      <DroneIcon className="DesignStatus-DroneIcon" />
      <div className="OrderResponseModal-Title">{t('DesignService.designCanceled.context')}</div>
      <div className="OrderResponseModal-Info">
        <div className="OrderResponseModal-InfoTitles">
          <h1>{t('DesignService.designCanceled.infoTitles.product')}</h1>
          <h1>{t('DesignService.designCanceled.infoTitles.credits')}</h1>
        </div>
        <hr />
        <div className="OrderResponseModal-InfoTitles">
          <p>{DESIGN_TIER_LEVEL_FILTERS.find((item) => item.value === tierLevel)?.text}</p>
          <p>{tierLevelCost}</p>
        </div>
        <hr />
        <div className="DesignStatus-Total">
          {/* TODO: Response should include credit costs, until then we hardcode it*/}
          {t('DesignService.designCanceled.infoTitles.total')} {DESIGN_SERVICE_CREDITS.wireframe}
        </div>
      </div>
      <div className="DesignStatus-Date">
        <h1>{t('DesignService.designCanceled.infoTitles.cancelDate')}</h1>
        <p>{formatDate(new Date())}</p>
      </div>
      <div className="DesignStatus-ButtonWrapper">
        <Button className="Button--White" onClick={() => handleMyProjectsButtonClick(history)}>
          {t('DesignService.designCanceled.buttonText.myProjects')}
        </Button>
        <Button
          className="Button--Blue DesignStatus-Button"
          onClick={() => history.push(orderDesignRoute(projectId?.id))}
        >
          {t('DesignService.designCanceled.buttonText.newOrder')}
        </Button>
      </div>
    </div>
  );
};

export default DesignCanceled;
