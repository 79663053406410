import { ACCESS } from 'helpers/constants/access';
import { s3dV2, s3dV3, s3dVSelect } from 'helpers/constants/routes';
import useFeatureToggle from 'helpers/hooks/useFeatureToggle';
import usePermissions from 'helpers/hooks/usePermissions';

const useS3D = () => {
  const { isScaniflyAdmin, isDesignServiceProvider } = usePermissions();

  const directUrl = useFeatureToggle(ACCESS.S3D_V3) ? s3dV3 : s3dV2;
  return isScaniflyAdmin || isDesignServiceProvider ? s3dVSelect : directUrl;
};

export default useS3D;
