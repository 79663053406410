import { useEffect, useState } from 'react';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';

import { Avatar } from 'antd';
import { t } from 'i18next';
import { Notification } from 'types';

import styled from 'styled-components';

import { notificationRequested } from 'state/slices/notificationSlice';
import { resetProject } from 'state/slices/projectSlice';
import { AppDispatch, RootState } from 'state/store';

import { Badge, Tooltip, UpgradeModal } from 'components';
import { MESSAGE_GROUP } from 'components/UpgradeTierLevelForAccess/constants';

import colors from 'helpers/constants/colors';
import { accountMenuRoute, requestFlightRoute } from 'helpers/constants/routes';
import { usePageTitle } from 'helpers/hooks/usePageTitle';
import useToggle from 'helpers/hooks/useToggle';

import { ReactComponent as GearIcon } from 'assets/icons/gear-icon.svg';
import { ReactComponent as NotificationBellIcon } from 'assets/notification-bell.svg';
import { ReactComponent as UserAvatarIcon } from 'assets/user-avatar.svg';

import './Header.scss';
import HeaderMenu from './HeaderMenu';
import NotificationMenu from './NotificationMenu';

const StyledNotificationIcon = styled(NotificationBellIcon)`
  width: 30px;
  height: 20px;
`;

const StyledGearIcon = styled(GearIcon)`
  height: 22px;
  width: 22px;
`;

const StyledIconBorder = styled.div`
  display: flex;
  background-color: #e4ebee;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  path {
    fill: ${colors.mainBlue};
  }
`;

const StyledLink = styled(Link)`
  background-color: transparent;
  border: none;
  outline: none;
  position: relative;
`;

const StyledRequestLink = styled.a`
  background-color: ${colors.mainBlue};
  color: white;
  border: none;
  border-radius: 8px;
  padding: 8px 12px;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 42px;
  margin: 4px 0 0 0;

  &:hover {
    background-color: ${colors.lightBlue};
    color: white;
  }
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  position: relative;
`;

const Wrapper = styled.div`
  display: flex;
`;

const Header = () => {
  const dispatch: AppDispatch = useDispatch();
  const [isMenuOpen, toggleMenu] = useToggle();
  const [isModalOpen, toggleModal] = useToggle();
  // Not using useToggle here because we want to close
  // the menu when the user clicks outside of it or on it.
  const [isNotificationOpen, toggleNotifications] = useState(false);
  const [sortedNotifications, setSortedNotifications] = useState<Notification[]>([]);
  const { currentUser, currentUserAvatar } = useSelector((state: RootState) => state.users);
  const notifications = useSelector((state: RootState) => state.notification);
  const { pathname } = useLocation();
  const title = usePageTitle(pathname);
  const menuRef = useOnclickOutside(() => {
    toggleNotifications(false);
  });

  let unreadNotifications = 0;
  useEffect(() => {
    if (notifications && notifications.notifications) {
      setSortedNotifications([...notifications.notifications].reverse());
    }
  }, [notifications]);

  const handleRouteChange = () => {
    dispatch(resetProject());
  };

  useEffect(() => {
    if (currentUser?.id) dispatch(notificationRequested(currentUser?.id));
  }, [currentUser?.id, dispatch]);

  if (sortedNotifications.length > 0) {
    sortedNotifications.map((notification) => {
      const { hasRead, visible } = notification;
      const read = hasRead.includes(currentUser?.id ?? '') ? true : false;
      const isHidden = visible.includes(currentUser?.id ?? '') ? true : false;
      if (read || isHidden) {
        return null;
      } else {
        unreadNotifications++;
      }
      return unreadNotifications;
    });
  }

  return (
    <>
      <UpgradeModal
        messageGroup={MESSAGE_GROUP.NOTIFICATIONS}
        isModalOpen={isModalOpen}
        toggleModal={toggleModal}
      />
      <header className="Header">
        <h1 className="MainTitle">{title}</h1>
        <Wrapper ref={menuRef}>
          <StyledRequestLink
            href={requestFlightRoute()}
            target="_blank"
            rel="noopener noreferrer"
            className="Header-Button"
            data-testid="request-flight-button"
          >
            {t('Header.request-flight')}
          </StyledRequestLink>
          <StyledButton onClick={() => toggleNotifications(!isNotificationOpen)}>
            <Tooltip bottom title={t('Header.notifications')}>
              <StyledIconBorder>
                <Badge number={unreadNotifications} />
                <StyledNotificationIcon />
              </StyledIconBorder>
            </Tooltip>
          </StyledButton>
          <StyledLink
            to={accountMenuRoute()}
            onClick={handleRouteChange}
            className="Header-Button"
            data-testid="settings-button"
          >
            <Tooltip bottom title={t('Header.settings')}>
              <StyledIconBorder>
                <StyledGearIcon />
              </StyledIconBorder>
            </Tooltip>
          </StyledLink>

          <button
            className="Header-MenuButton Header-Button"
            onClick={toggleMenu}
            data-testid="profile-button"
          >
            {currentUserAvatar ? (
              <Avatar size={40} src={`data:image/png;base64,${currentUserAvatar}`} alt="" />
            ) : (
              <UserAvatarIcon fill={'#1C48F1'} />
            )}
            {currentUser && (
              <span className="Header-MenuButton-Name">
                {`${currentUser?.firstName} ${currentUser?.lastName}`}
              </span>
            )}
          </button>
          {isNotificationOpen && <NotificationMenu notifications={sortedNotifications} />}
          {isMenuOpen && <HeaderMenu toggleMenu={toggleMenu} />}
        </Wrapper>
      </header>
    </>
  );
};

export default Header;
