import { Tooltip } from '@cpm/scanifly-shared-components';
import colors from 'helpers/constants/colors';
import fontSizes from 'helpers/constants/fontSizes';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  display: inline-block;
  width: auto;
  border-radius: 1rem;
  padding: 0.75rem 1.5rem;
  background: ${colors.neutralBlue};
  color: ${colors.mainBlue};
  cursor: pointer;
  font-size: ${fontSizes.small};
  white-space: nowrap;
  margin-bottom: 1rem;

  &:hover {
    background: ${colors.neutralBlueLightOpacity};
    box-shadow: 0 0 1px rgba(9, 30, 66, 0.25);
  }
`;

export const ToggleLegacyViewButton = ({ toLegacy }: { toLegacy?: boolean }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const searchParams = useMemo(() => {
    const params = new URLSearchParams(location.search);
    params.set('legacy', toLegacy ? '1' : '0');
    return params;
  }, [location.search, toLegacy]);

  return (
    <Tooltip
      title={
        toLegacy
          ? t('AlbumView.legacyAlbumsToggleTooltipText')
          : t('AlbumView.newAlbumsToggleTooltipText')
      }
      center
      bottom={toLegacy}
    >
      <StyledLink
        to={{
          ...location,
          search: searchParams.toString(),
        }}
        data-testid={toLegacy ? 'back-to-legacy-albums-button' : 'back-to-new-albums-button'}
      >
        {toLegacy ? t('AlbumView.legacyAlbumsView') : t('AlbumView.newAlbumsView')}
      </StyledLink>
    </Tooltip>
  );
};
