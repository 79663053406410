import * as Sentry from '@sentry/react';
import { memo, useCallback, useEffect, useRef } from 'react';

import { refreshToken } from 'api/auth/authService';

import { useViewportSize } from 'helpers/hooks/useViewportSize';
import { setTokens } from 'helpers/utils/manageTokens';

type IFrameProps = {
  src: string;
};

const iframeId = 'S3DIframe';
const iframeStyle = { border: 'none', position: 'absolute' } as const;

function IFrame({ src }: IFrameProps) {
  const { height, width } = useViewportSize();

  const ref = useRef<HTMLIFrameElement>(null);

  const onLoad = useCallback(() => {
    // Set focus on iframe after load for keyboard events
    const iframe = document.getElementById(iframeId);
    // @ts-ignore
    if (iframe) iframe.contentWindow.focus();
  }, []);

  useEffect(() => {
    const handler = async (event: MessageEvent<any>) => {
      if (!ref.current) return;
      if (event.data === 'refreshToken') {
        const token = localStorage.getItem('refreshToken');
        const response = await refreshToken(token ?? '');
        const { refreshToken: newRefreshToken, accessToken } = response.data;
        setTokens(accessToken, newRefreshToken);
        ref.current?.contentWindow?.postMessage(
          { accessToken },
          process.env.REACT_APP_S3D_URL ?? ''
        );
      }
      if (event.data === 'DESIGN_FINALIZED') {
        try {
          const parentWindow = window.parent;
          if (parentWindow) {
            parentWindow.postMessage('DESIGN_FINALIZED', '*');
          }
        } catch (e) {
          Sentry.captureException(e);
        }
      }
    };
    window.addEventListener('message', handler);
    return () => window.removeEventListener('message', handler);
  }, []);

  return (
    <iframe
      allow="clipboard-read; clipboard-write;"
      id={iframeId}
      title="Scanifly3D"
      onLoad={onLoad}
      ref={ref}
      role="application"
      className="model-viewer"
      style={iframeStyle}
      width={width ?? '100%'}
      height={height ?? '100%'}
      src={src}
    />
  );
}

function propsAreEqual(prevProps: IFrameProps, nextProps: IFrameProps) {
  return prevProps.src === nextProps.src;
}

export default memo(IFrame, propsAreEqual);
