import { DESIGN_SERVICE_CREDITS, TIER_LEVEL } from '@cpm/scanifly-shared-data';

export const calculateTierLevelCost = ({
  upgrade,
  hasSitePlanRequest,
  hasTrueUpRequest,
  tierLevel,
}: {
  upgrade?: boolean;
  hasSitePlanRequest: number;
  hasTrueUpRequest: number;
  tierLevel: TIER_LEVEL;
}) => {
  if (upgrade) {
    if (hasSitePlanRequest) {
      return DESIGN_SERVICE_CREDITS[tierLevel] - DESIGN_SERVICE_CREDITS.sitePlan;
    }
    if (hasTrueUpRequest) {
      return DESIGN_SERVICE_CREDITS[tierLevel] - DESIGN_SERVICE_CREDITS.trueUp;
    }
    return DESIGN_SERVICE_CREDITS[tierLevel] - DESIGN_SERVICE_CREDITS.wireframe;
  }
  return DESIGN_SERVICE_CREDITS[tierLevel];
};
