import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import {
  DESIGN_SERVICE_CREDITS,
  DESIGN_STATUS,
  DESIGN_TIER_LEVEL_FILTERS,
  estimateTurnaroundTimeForLarge,
  estimateTurnaroundTimeForSmall,
  FEATURE_LIST,
  GENERAL_SERVICE_CREDITS,
  getExtraCosts,
  PROJECT_TYPES,
  TIER_LEVEL,
} from '@cpm/scanifly-shared-data';
import { Button } from 'antd';
import { TFunction } from 'i18next';

import { companySubscriptionInfoRequested } from 'state/slices/companySlice';
import { AppDispatch, RootState } from 'state/store';

import { designStatusRoute } from 'helpers/constants/routes';
import { formatDate } from 'helpers/utils/dateFormat';
import { calculateTierLevelCost } from 'screens/DesignServices/helpers/calculateTierLevelCost';
import { getDesignStatusStep } from 'screens/DesignServices/helpers/getDesignStatusStep';
import { getTitle } from 'screens/DesignServices/helpers/getTitle';

import { ReactComponent as DroneIcon } from 'assets/icons/drone-icon.svg';

type SuccessContentProps = {
  handleModalClose: Function;
  responseData: any;
  upgrade: boolean;
};

const SuccessContent = ({ handleModalClose, responseData, upgrade }: SuccessContentProps) => {
  const history = useHistory();
  const dispatch: AppDispatch = useDispatch();
  const { t } = useTranslation();
  const { tierLevel, project, status, createdAt } = responseData || {};
  const { status: projectStatus, id: projectId, type: projectType } = project || {};
  const timeEstimates =
    tierLevel === TIER_LEVEL.wireframeForLarge || tierLevel === TIER_LEVEL.planSetForLarge
      ? { design: estimateTurnaroundTimeForLarge(tierLevel) }
      : estimateTurnaroundTimeForSmall({
          tierLevel,
          isExpedited: responseData?.isExpedited ?? false,
          isCommercial: responseData?.form?.isCommercialZone ?? false,
        });
  const step = getDesignStatusStep({ projectStatus, status });
  const { company, companySubscriptionInfo } = useSelector((state: RootState) => state.company);
  const { serviceRequests } = useSelector((state: RootState) => state.designServiceRequests);
  const isMaxFillOrSiteModelingRequest =
    tierLevel === TIER_LEVEL.maxFill || tierLevel === TIER_LEVEL.siteModeling;

  const hasSitePlanRequest = serviceRequests
    ? serviceRequests.filter(
        (r) => r.tierLevel === TIER_LEVEL.sitePlan && r.status !== DESIGN_STATUS.canceled
      ).length
    : 0;

  const hasTrueUpRequest = serviceRequests
    ? serviceRequests.filter(
        (r) => r.tierLevel === TIER_LEVEL.trueUp && r.status !== DESIGN_STATUS.canceled
      ).length
    : 0;

  const costForLargeProjects = useMemo(
    () =>
      upgrade
        ? DESIGN_SERVICE_CREDITS.planSetForLarge - DESIGN_SERVICE_CREDITS.wireframeForLarge
        : DESIGN_SERVICE_CREDITS[tierLevel],
    [tierLevel, upgrade]
  );

  const tierLevelCost =
    projectType === PROJECT_TYPES.LARGE
      ? costForLargeProjects
      : calculateTierLevelCost({ upgrade, hasSitePlanRequest, hasTrueUpRequest, tierLevel });

  const allExtraCosts = getExtraCosts({
    tierLevel: tierLevel,
    isExpedited: responseData?.isExpedited,
    newBuildPlansRequired: responseData?.form?.newBuildPlansRequired,
    isCommercial: responseData?.form?.isCommercialZone,
  });

  const newBuildCost = responseData?.form?.newBuildPlansRequired
    ? GENERAL_SERVICE_CREDITS[FEATURE_LIST.DESIGN_SERVICE_NEW_BUILD_PLANS]
    : 0;

  useEffect(() => {
    if (company) {
      dispatch(companySubscriptionInfoRequested(company?.id));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="OrderResponseModal-Wrapper">
      <DroneIcon className="OrderResponseModal-DroneIcon" />
      <div className="OrderResponseModal-Title">{t('DesignService.title')}</div>
      <div className="OrderResponseModal-Info">
        <div className="OrderResponseModal-InfoTitles">
          <h2>{t('DesignService.service')}</h2>
          <h2>{t('DesignService.credits')}</h2>
        </div>
        <hr />
        <div className="OrderResponseModal-InfoTitles">
          <p data-testid="design-tier">
            {`${DESIGN_TIER_LEVEL_FILTERS.find((item) => item.value === tierLevel)?.text} ${responseData?.isExpedited ? `(${t('DesignService.expedited')})` : ''}`}
          </p>
          <p data-testid="credit-cost">{tierLevelCost + allExtraCosts - newBuildCost}</p>
        </div>
        <hr />

        {responseData?.form?.newBuildPlansRequired ? (
          <>
            <div className="OrderResponseModal-InfoTitles">
              <p data-testid="new-build-plans">{t('DesignService.newBuild')}</p>
              <p data-testid="new-build-credit-cost">{newBuildCost}</p>
            </div>
            <hr />
          </>
        ) : null}

        <div className="OrderResponseModal-TotalWrapper">
          <p className="OrderResponseModal-TotalValue">
            {t('DesignService.total')}: {tierLevelCost + allExtraCosts}
          </p>
          <p className="OrderResponseModal-TotalValue" data-testid="remaining-balance">
            {/* TODO: Response should include credit costs, until then we hardcode it*/}
            {`${t('DesignService.remaining')}:
            ${
              companySubscriptionInfo
                ? (companySubscriptionInfo?.credits?.amount ?? 0).toLocaleString('en-US')
                : 0
            }`}
          </p>
        </div>
        <>
          {Object.entries({
            createdAt: formatDate(createdAt),
            design: timeEstimates.design ?? 0,
          }).map(([key, value]) => {
            return (
              <CustomEstimate
                key={key}
                estimateTitle={getTitle(key, isMaxFillOrSiteModelingRequest) ?? ''}
                value={value}
                timeEstimates={timeEstimates}
                t={t}
              />
            );
          })}
        </>
      </div>
      <Button
        className="Button--Blue OrderResponseModal-Button"
        onClick={() => {
          handleModalClose();
          history.push(designStatusRoute(projectId, String(step)));
        }}
      >
        Close
      </Button>
    </div>
  );
};

type TimeEstimates = {
  design?: number | string;
  total?: number;
  unit?: 'hours' | 'minutes';
};

type CustomEstimateProps = {
  value: number | string;
  estimateTitle: string;
  timeEstimates: TimeEstimates;
  t: TFunction<'translation', undefined>;
};

const CustomEstimate = ({ value, estimateTitle, timeEstimates, t }: CustomEstimateProps) => {
  const isTotal = estimateTitle === t('DesignService.totalEstimate');
  const isDesign = estimateTitle === t('DesignService.designEstimate');
  const isDate = estimateTitle === t('DesignService.orderDate');
  const getTimeUnit = (value: number | string, timeEstimates: TimeEstimates | undefined) => {
    if (timeEstimates?.unit === 'minutes') {
      return t('DesignService.minutes');
    } else if (Number(value) < 24 || typeof value === 'string' || timeEstimates?.unit === 'hours') {
      return t('DesignService.hours');
    } else if (value === 24) {
      return t('DesignService.day');
    }
    return t('DesignService.days');
  };
  const timeUnit = getTimeUnit(value, timeEstimates);
  let lineItem = `${value} ${timeUnit}`;
  if (isDate) lineItem = String(value);
  const timeEstimatesLength = Object.keys(timeEstimates).length;

  return (
    <>
      {(isDesign && timeEstimatesLength === 1) || (isTotal && timeEstimatesLength > 1) ? (
        <hr />
      ) : null}
      <div className="OrderResponseModal-InfoTitles">
        <h2 className="heading" id={isDate ? 'date' : estimateTitle}>
          {estimateTitle}
        </h2>
        <h2 className="OrderResponseModal-Value">{lineItem}</h2>
      </div>
    </>
  );
};

export default SuccessContent;
