import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import styled from 'styled-components';

import { ModalContext } from 'components/Modal';
import UploadThumbnail from 'components/UploadThumbnail';

import colors from 'helpers/constants/colors';

import placeholderImage from 'assets/thumbnail-placeholder.svg';

import { ReferenceMediaPreviewProps } from './types';

const StyledContainer = styled.div`
  margin-top: 2rem;
  border-radius: 10px;
  border: 1px solid ${colors.neutralGray};
  background-color: ${colors.background};
  padding-top: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;

  span {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  img {
    margin-bottom: 1rem;
  }
`;

/**
 * Renders thumbnails of reference media with optional ability to delete them
 */
function ReferenceMediaPreview({
  categoryId,
  handleDelete,
  referenceMedia,
}: ReferenceMediaPreviewProps) {
  const { t } = useTranslation();

  const { displayDeleteModal } = useContext(ModalContext);

  const onDelete = useCallback(
    (params: { mediaId: string; categoryId: string }) => {
      displayDeleteModal({
        title: t('ReferenceMedia.deleteImage'),
        description: t('ReferenceMedia.deleteImageAreYouSure'),
        actionButtonLabel: t('ReferenceMedia.deleteImage'),
        actionButtonOnClick: () => handleDelete!(params),
      });
    },
    [handleDelete, displayDeleteModal] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <StyledContainer>
      {referenceMedia?.map((media, index) => (
        <UploadThumbnail
          key={index}
          src={media.thumbnailUrl ?? media.imgUrl ?? placeholderImage}
          handleDelete={
            handleDelete ? () => onDelete({ mediaId: media.id, categoryId }) : undefined
          }
          isVideo={false}
        />
      ))}
    </StyledContainer>
  );
}

export default ReferenceMediaPreview;
